import service from '@/utils/request';
export var mpCodeTmpList = function mpCodeTmpList() {
  return service({
    url: "/mp_code_tmps/list",
    method: 'get'
  });
};
export var mpCodeTmpSync = function mpCodeTmpSync() {
  return service({
    url: "/mp_code_tmps/sync",
    method: 'get'
  });
};
export var mpCodeTmpManage = function mpCodeTmpManage(data) {
  return service({
    url: "/mp_code_tmps/manage",
    method: 'post',
    data: data
  });
};